var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "basicForm",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-col": _vm.labelCol,
        "wrapper-col": _vm.wrapperCol,
        autocomplete: "off",
      },
    },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: "薪资组名称", prop: "groupName" } },
        [
          _c("a-input", {
            attrs: { placeholder: "1-30个字符" },
            on: {
              input: function ($event) {
                _vm.form.groupName = _vm.form.groupName.replace(
                  /[^\w,\u4e00-\u9fa5,(,),（,）,_,-]/g,
                  ""
                )
              },
            },
            model: {
              value: _vm.form.groupName,
              callback: function ($$v) {
                _vm.$set(_vm.form, "groupName", $$v)
              },
              expression: "form.groupName",
            },
          }),
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: "启用年月", prop: "startPeriod" } },
        [
          _c("a-month-picker", {
            attrs: { "value-format": "YYYY-MM", format: "YYYY-MM" },
            model: {
              value: _vm.form.startPeriod,
              callback: function ($$v) {
                _vm.$set(_vm.form, "startPeriod", $$v)
              },
              expression: "form.startPeriod",
            },
          }),
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: "适用员工", prop: "userList" } },
        [
          _c("CbSelectPersons", {
            attrs: {
              "query-leave-person": true,
              "data-echo": _vm.form.userList,
            },
            on: { perModelConfirm: _vm.perModelConfirm },
          }),
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: "是否启用" } },
        [
          _c("a-switch", {
            model: {
              value: _vm.form.enabled,
              callback: function ($$v) {
                _vm.$set(_vm.form, "enabled", $$v)
              },
              expression: "form.enabled",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }